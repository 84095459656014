<template>
	<edit-template id="work-add" style="padding-top: 70rem" @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(res=>$router.back());" :cancelRemind="false" confirmText="保存" cancelText="取消">

    <el-form ref="form" :model="form" :rules="rules" class="form" style="min-height: calc(100vh - 294rem)" label-width="150rem">
      <el-form-item label="校区" prop="school_id">
        <el-select v-model="form.school_id" @change="schoolChange">
          <el-option v-for="(item,index) in userInfo.school_arr" :label="item" :value="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代课学科" prop="subject_id">
        <el-select v-model="form.subject_id" @change="subjectChange" placeholder="请选择学科">
          <el-option v-for="item in subjectOptions" :key="item.id" :label="item.subject_name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否同科目" prop="same_subject">
        <el-switch v-model="form.same_subject" @change="switchChange" active-color="#1d2088" inactive-color="rgba(0,0,0,.2)">
        </el-switch>
      </el-form-item>
      <el-form-item label="被代课教师" prop="agent_teacher_id">
        <el-select :disabled="!(form.school_id && form.subject_id)" v-model="form.agent_teacher_id" filterable="" remote="" reserve-keyword="" placeholder="搜索被代课的教师" :remote-method="querySearch1" @change="agentTeacherHandle" :loading="loading">
          <el-option v-for="item in teacher1_options" :key="item.teacher_id" :label="item.clerk_name" :value="item.teacher_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代课课节" prop="course">
        <date-picker ref="dataPicker" v-model="date" placeholder="请选择开始课节" @onClear="onClear" @onChange="onChangeDate" :disabled="!form.agent_teacher_id">
          <el-checkbox-group slot="right" class="course" v-model="course" @change="changeCourse">
            <el-checkbox v-for="(item,index) in courseList" border="" :label="JSON.stringify(item)" :key="'date-'+index">
              <div>
                <p>日期: {{ item.date }}</p>
                <p>课节数: {{ item.courseName }}</p>
                <p>{{ item.StartTime }}-{{ item.EndTime }}</p>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </date-picker>
        <div style=" width: 500rem;">
          <el-tag v-for="(item,index) in course" style="margin-right: 10rem;" closable="" @close="removeCourse(index)" :key="'el-tag-'+index">
            {{ renderCourse(item) }}
          </el-tag>
        </div>
      </el-form-item>
      <el-form-item label="实际上课教师" prop="teacher_id">
        <el-select :disabled="!form.course.length" v-model="form.teacher_id" filterable="" remote="" reserve-keyword="" placeholder="搜索实际上课的教师" :remote-method="querySearch2" :loading="loading">
          <el-option v-for="item in teacher2_options" :key="item.teacher_id" :label="item.clerk_name" :value="item.teacher_id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>



</edit-template>
</template>

<script>
import datePicker from "@/components/DatePicker"
import {mapGetters} from "vuex"

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    let validateDate = (rule, value, callback) => {
      if (!this.date && this.course.length === 0) return callback(new Error("请选择代课时间及课节"))
      if (!this.date) callback(new Error("请选择代课时间"));
      else if (this.course.length === 0) callback(new Error("请选择代课课节"))
      else callback();
    }
    return {
      loading: false,
      form: {
        school_id: null,
        agent_teacher_id: null,
        same_subject: null,
        subject_id: null,
        teacher_id: null,
        course: []
      },
      teacher1_options: [],
      teacher2_options: [],
      subjectOptions: [],
      teacher1_string: "",
      teacher2_string: "",
      date: null, // 代课时间
      course: [], // 课节
      courseList: [],
      rules: {
        school_id: [{required: true, message: "请选择校区"}],
        subject_id: [{required: true, message: "请选择代课学科"}],
        same_subject: [{required: true, message: "请选择是否同科目"}],
        agent_teacher_id: [{required: true, message: "请输入被代课教师"}],
        course: [{required: true, type: "array", message: "请选择代课时间及课节", trigger: "blur"}, {
          validator: validateDate,
          type: "array",
          trigger: "change"
        }],
        teacher_id: [{required: true, message: "请选择实际上课教师"}],
      },
    }
  },
  mounted() {
    this.getSubject()
  },
  watch: {},
  computed: {
    ...mapGetters(["userInfo"])
  },
  components: {
    datePicker
  },
  methods: {
    getSubject() {
      this.$_axios2.get("api/sub/substitute/subject").then(res => {
        this.subjectOptions = res.data.data
      })
    },

    renderCourse(str) {
      let item = JSON.parse(str);
      return item.date + " " + item.courseName
    },
    removeCourse(index) {
      this.course.splice(index, 1);
      this.changeCourse(this.course);
    },
    // 课节选择
    changeCourse(e) {
      let courseList = [];
      for (let course of e) {
        if (typeof course === "string")
          course = JSON.parse(course);
        courseList.push({
          id: course.id,
          schedule_id: course.schedule_id,
          day: course.date
        })
      }
      this.form.course = courseList;
      this.form.teacher_id = '';
      this.teacher2_options = [];
      this.querySearch2(' ');
      this.$forceUpdate();
    },
    // 选择时间
    onChangeDate(e) {
      this.courseList = [];
      if (e.format && e.format.length)
        this.$_axios2.get("/api/sub/substitute/schedule", {
          params: {
            teacher_id: this.form.agent_teacher_id,
            day: e.format.join(",") // e.format
          }
        }).then(res => {
          let {data} = res.data;
          let temp = [];
          for (let k in data) {
            let item = data[k];
            item.forEach(c => c.date = k)
            temp = temp.concat(item);
          }
          this.courseList = temp;
          let courseTemp = [];
          temp.forEach(item => {
            let sItem = JSON.stringify(item);
            if (this.course.includes(sItem))
              courseTemp.push(sItem);
          })
          this.course = courseTemp;
          this.changeCourse(courseTemp);
        })
      else {
        this.course = [];
        this.form.course = [];
      }
    },
    // 教师搜索被选中
    handleSelect(e, type) {
      type === 1 ?
          this.form.agent_teacher_id = e.teacher_id : this.form.teacher_id = e.teacher_id;
    },

    agentTeacherHandle(){
      this.$refs.dataPicker.clearDate()
      this.form.teacher_id = null
      this.form.course = []
    },

    switchChange() {
      this.form.teacher_id = '';
      this.teacher2_options = [];
      this.querySearch2(' ');
      this.$forceUpdate();
    },

    // 搜索被代理教师
    querySearch(str, type) {
      if (str == null || str === "") return;
      this.loading = true;
      let params = {
        school_id: this.form.school_id,
        name: str,
        subject_id: this.form.subject_id,
        type
      }
      if (type === 2) {
        let arr = this.form.course.map(item => item.schedule_id)
        params.schedule_id = arr.join(',')
        params.agent_teacher = this.form.agent_teacher_id
        if (!this.form.same_subject) params.subject_id = 0
      }
      this.$_axios2.get("/api/sub/substitute/teacher", {params}).then(res => {
        let data = res.data.data;
        this.loading = false;
        this["teacher" + type + "_options"] = data;
      }).catch(err => {
        this.loading = false;
      })
    },

    querySearch2(str) {
      this.querySearch(str, 2);
    },
    querySearch1(str) {
      this.querySearch(str, 1);
    },
    // 校区被更改
    schoolChange() {
      this.form.agent_teacher_id = null;
      this.form.teacher_id = null;
      this.form.course = [];
      this.teacher1_options = [];
      this.teacher2_options = [];
      if (!this.date) return;
      this.$refs.dataPicker.clearDate();
      // this.date = null;
      this.course = [];
      this.courseList = [];
    },

    subjectChange() {
      this.schoolChange()
    },

    // 代课课节右侧的清除按钮
    onClear() {
      this.courseList = [];
      this.course = [];
      this.form.course = [];
      this.form.teacher_id = '';
      this.teacher2_options = [];
    },
    submit() {
      let {school_id} = this.form;
      let form = this.$refs.form;
      form.validate((res, props) => {
        if (res) {
          let params = {
            ...this.form,
            same_subject: this.form.same_subject ? 1 : 0
          }
          this.$_axios2.post("/api/sub/substitute/add", params).then(res => {
            if (res.data.status === 0) {
              this.$store.commit("setPage", 1);
              this.$router.back();
            }
          })
        }
      })

    }
  }
}
</script>

<style scoped lang="scss">
#work-add {
  padding-top: 35rem;

  .form {

    font-size: 14rem;
    margin: 0 0 20rem 50rem;

    .el-form-item {
      margin-bottom: 30rem;
    }
  }

  .el-checkbox {
    height: 80rem;
    margin: 0 0 10rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150rem;

    ::v-deep span.el-checkbox__input {
      display: none;
    }
  }

  ::v-deep .el-checkbox.is-checked {
    background-color: #1d2088;

    .el-checkbox__label {
      color: white;
    }
  }

  .course {
    //text-align: center;
    line-height: 30rem;
    max-height: 353rem;
    width: 100%;
    overflow-y: scroll;
    padding-top: 20rem;

    p {
      line-height: 24rem;
    }

    li {
      padding: 10rem 5rem;
      border-bottom: 1rem solid #eeeeee;
      cursor: pointer;
    }

    li:last-child {
      border-bottom: none;
    }

    li:hover {
      background-color: #eeeeee;
    }

    li.courseActive {
      background-color: #1d2088;
      color: white;
    }
  }

}
</style>
